.app__testimonial {
  flex: 1;
  width: 100%;
  flex-direction: column;

  h2 {
    padding-bottom: 3rem;
  }
}

.app__testimonial-item {
  width: 80%;
  min-height: 320px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;

    @media screen and (max-width: 600px) {
      height: 50vh;
    }
  }
}

.app__testimonial-btns {
  flex-direction: row;
  margin-top: 1rem;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 1rem;
    transition: all 0.3s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }
}
